"use client";

import { useScreenWidth } from "@/utils/useScreenWidth";
import { useMemo } from "react";
import { Toaster, ToastBar } from "react-hot-toast";

export const CustomToaster = () => {
	const screenWidth = useScreenWidth();
	const position = useMemo(
		() => (screenWidth < 1024 ? "top-right" : "bottom-right"),
		[screenWidth]
	);

	return (
		<Toaster
			position={position}
			containerStyle={{
				zIndex: 99999,
			}}
		>
			{(t) => (
				<ToastBar
					position={position}
					toast={{
						...t,
						className:
							"backdrop-blur-md bg-opacity-20 border border-neutral-700",
						style: {
							backgroundColor: "rgb(64 64 64 / 0.5)",
							color: "white",
						},
					}}
				>
					{({ icon, message }) => (
						<>
							{icon}
							{message}
						</>
					)}
				</ToastBar>
			)}
		</Toaster>
	);
};
