import clsx from "clsx";
import { ReactNode, useEffect, useRef } from "react";
import SSRDocumentBodyPortal from "./ssrDocumentPortal";

export type ClosableModalProps = {
	isOpen: boolean;
	onClose: () => void;

	children: ReactNode;
	classname?: string;

	animRef?: React.RefObject<HTMLDivElement | null>;
	relative?: boolean;
};

export default function ClosableModal({
	isOpen,
	onClose,
	children,
	classname,
	animRef,
	relative,
}: ClosableModalProps) {
	const modalRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (isOpen && modalRef.current) {
			modalRef.current.focus();
		}
	}, [isOpen]);

	const content = (
		<div
			ref={animRef}
			className={clsx(
				"grid place-items-center transition-all overflow-auto",
				{
					"w-full h-full": relative,
					"fixed inset-0 z-[9999] w-screen h-dvh": !relative,
					"opacity-0 pointer-events-none": !isOpen,
					"backdrop-blur-md": isOpen,
				},
				classname
			)}
			onClick={(e) => {
				onClose();
				e.stopPropagation();
			}}
		>
			<div
				ref={modalRef}
				tabIndex={-1} // Make the div focusable
				className={clsx("transition-all grid place-items-center outline-none", {
					"scale-95": !isOpen,
				})}
				onClick={(e) => e.stopPropagation()}
				onKeyDown={(e) => {
					if (e.key === "Escape") {
						onClose();
						e.stopPropagation(); // Prevent the event from bubbling up
					}
				}}
			>
				{children}
			</div>
		</div>
	);
	return relative ? (
		content
	) : (
		<SSRDocumentBodyPortal>{content}</SSRDocumentBodyPortal>
	);
}
