import clsx from "clsx";
import { ButtonHTMLAttributes } from "react";
import Spinner from "../../spinners/spinner";

type Props = Readonly<{
	children: React.ReactNode;
	className?: string;

	selected?: boolean;
	colored?: "blue" | "red";
	isProcessing?: boolean;
}> &
	Readonly<ButtonHTMLAttributes<HTMLButtonElement>>;

export default function Button(props: Props) {
	const {
		children,
		className,
		selected,
		colored,
		isProcessing,
		...buttonProps
	} = props;
	return (
		<button
			{...buttonProps}
			className={clsx(
				"bg-black select-none transition-all grid place-items-center hover:bg-opacity-100 disabled:border-opacity-80 disabled:bg-opacity-20 disabled:cursor-not-allowed",
				{
					"bg-neutral-700 border-neutral-700": !colored,
					"bg-sky-400 border-sky-400": colored === "blue",
					"bg-destructive border-destructive": colored === "red",
					"bg-opacity-100": selected,
					"bg-opacity-40": !selected,
				},
				className
			)}
			disabled={buttonProps.disabled || isProcessing}
		>
			{isProcessing ? <Spinner className="w-6 h-6" /> : children}
		</button>
	);
}
