"use client";
import { ReactNode, useRef } from "react";
import ClosableModal from "./closableModal";
import clsx from "clsx";
import { FieldValues } from "react-hook-form";
import { renderTextWithNewlines } from "@/utils/renderTextWithNewlines";
import FormModal, { FormModalProps } from "./formModal";

export type formInput = {
	label?: string;
	className?: string;
	hidden?: boolean;
	children: ReactNode;
};

export function FormInput({ label, className, hidden, children }: formInput) {
	const hiddenChildren = hidden ? null : children;
	return (
		<div
			className={clsx(
				{
					hidden,
				},
				className
			)}
		>
			<label className={clsx("text-neutral-500 flex flex-col")}>
				{label && renderTextWithNewlines(label)}
				{hiddenChildren}
			</label>
		</div>
	);
}

export type PopupFormModalProps<T extends FieldValues> = {
	isOpen: boolean;
	onClose: () => void;
	waitForAnimation?: boolean;
} & Omit<FormModalProps<T>, "onCancel">;

export default function PopupFormModal<T extends FieldValues>({
	isOpen,
	onClose: parentOnClose,
	waitForAnimation,

	className,

	...formProps
}: PopupFormModalProps<T>) {
	const animRef = useRef<HTMLDivElement>(null);
	const onClose = () => {
		waitForAnimation
			? animRef.current!.addEventListener("transitionend", parentOnClose, {
					once: true,
			  })
			: parentOnClose();
	};

	return (
		<ClosableModal animRef={animRef} onClose={onClose} isOpen={isOpen}>
			<FormModal
				onCancel={onClose}
				className={clsx(className, "border")}
				{...formProps}
			/>
		</ClosableModal>
	);
}
