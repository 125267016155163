// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

;globalThis["_sentryRewritesTunnelPath"] = "/monitoring";globalThis["SENTRY_RELEASE"] = {"id":"futura-frontend@fbd240fd70a48e62e92a4a40468798ef1c8dba99"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import * as Sentry from "@sentry/nextjs";

Sentry.init({
	dsn: "https://acf0c53fd8c03493025421fe1b21a8d2@o4507620765728768.ingest.us.sentry.io/4507620784930816",

	// Adjust this value in production, or use tracesSampler for greater control
	tracesSampleRate: 1,

	// Setting this option to true will print useful information to the console while you're setting up Sentry.
	debug: !true,

	replaysOnErrorSampleRate: 1.0,

	// This sets the sample rate to be 10%. You may want this to be 100% while
	// in development and sample at a lower rate in production
	replaysSessionSampleRate: 0.1,

	// You can remove this option if you're not planning to use the Sentry Session Replay feature:
	integrations: [
		Sentry.replayIntegration({
			// Additional Replay configuration goes in here, for example:
			maskAllText: !true,
			blockAllMedia: !true,
		}),
	],

	release: process.env.NEXT_PUBLIC_SENTRY_RELEASE, // for some reason this sentry client doesnt read the env... so we must manually specify
	environment: process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT,
});
