import { useEffect, useRef } from "react";
import { MouseEventRecording, recordEvents } from "./recordEvents";

const minBufferedEvents = 50;

export default function useMouseRecorder() {
	const bufferedEvents = useRef<MouseEventRecording[]>([]);

	useEffect(() => {
		const bufferEvent = (e: MouseEvent) => {
			bufferedEvents.current.push({
				type: e.type,
				clientX: e.clientX,
				clientY: e.clientY,
				timeStamp: e.timeStamp,
			});
			if (
				e.type === "mouseup" &&
				bufferedEvents.current.length > minBufferedEvents
			) {
				recordEvents(
					window.innerWidth,
					window.innerHeight,
					bufferedEvents.current
				);
				bufferedEvents.current = [];
			}
		};
		window.addEventListener("mousemove", bufferEvent);
		window.addEventListener("mousedown", bufferEvent);
		window.addEventListener("mouseup", bufferEvent);

		return () => {
			window.removeEventListener("mousemove", bufferEvent);
			window.removeEventListener("mousedown", bufferEvent);
			window.removeEventListener("mouseup", bufferEvent);
		};
	}, []);
}
