import clsx from "clsx";
import { ComponentProps } from "react";
import Button from "./button";

export default function RoundedButton(props: ComponentProps<typeof Button>) {
	return (
		<Button
			{...props}
			className={clsx("rounded-lg border sm:px-5 px-1 py-4", props.className)}
		/>
	);
}
